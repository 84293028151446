import './index.styl'
import { ReactElement, useEffect, useState } from 'react'
import { NavOpener } from '../NavOpener'
import { Nav } from '../Nav'
import { Container } from '../Container'
import classNames from 'classnames'
import { LogoCebia, LogoCebiaSmartCode, LogoGjirafa } from '../LogoCebia'
import { ReactQueryDevtoolsPanel } from 'react-query/types/devtools'
import { ReferenceType } from '../../enums/ReferenceType'
import { i18n } from '../../translations/i18n'
import { OrderStep } from '../OrderStep'
import { SectionHeaderCaption } from '../../pages/detailPaid/sections/SectionHeaderCaption'
import { PayedStateModel } from '../../models/payedResponseModel'
import { CouponType } from '../../enums/CouponType'
import { retNavMenu } from '../../pages/detailPaid'

type Props = {
    variant?: 'light'
    showLanguage: true | false
    referenceType: ReferenceType
    isDemo?: boolean
    stateModel?: PayedStateModel | undefined
}

export const Header = ({
    variant,
    showLanguage,
    referenceType,
    isDemo,
    stateModel,
}: Props): ReactElement => {
    const className = classNames('Header', {
        [`Header--${variant}`]: variant,
    })
    const [pathName, setPathName] = useState(window.location.href.toLowerCase())

    useEffect(() => {
        setPathName(window.location.href.toLowerCase())
    })

    return (
        <header className={className}>
            <Container
                variant="full"
                className={`${pathName.indexOf('carinfo/payment/') >= 0 ? ' step' : ''}`}
            >
                <div
                    className={
                        pathName.indexOf('carinfo/payment/') >= 0
                            ? 'Header-contentStep'
                            : 'Header-content'
                    }
                >
                    <div className="d-flex">
                        <div
                            className={`Header-logo${
                                pathName.indexOf('carinfo/payment/') >= 0 ? '-Step' : ''
                            }${
                                referenceType == ReferenceType.SmartCode
                                    ? '-smart'
                                    : i18n.language !== 'al'
                                    ? ''
                                    : '-gjirafa'
                            }`}
                        >
                            <a href="/">
                                {referenceType == ReferenceType.SmartCode ? (
                                    i18n.language === 'cz' ? (
                                        <LogoCebiaSmartCode />
                                    ) : (
                                        <LogoCebia variant={variant} />
                                    )
                                ) : (
                                    <>
                                        {i18n.language !== 'al' ? (
                                            <LogoCebia variant={variant} />
                                        ) : (
                                            <LogoGjirafa variant={variant} />
                                        )}
                                    </>
                                )}
                            </a>
                        </div>
                    </div>
                    {referenceType != ReferenceType.SmartCode && (
                        <>
                            {pathName.indexOf('carinfo/payment/') >= 0 ? (
                                <div className="Header-navStep">
                                    <OrderStep />
                                </div>
                            ) : (
                                <>
                                    {pathName.indexOf('payed/detail/') >= 0 &&
                                    !stateModel?.isSample ? (
                                        <SectionHeaderCaption
                                            isDemo={isDemo}
                                            referenceType={retNavMenu(stateModel)}
                                            stateModel={stateModel}
                                        />
                                    ) : (
                                        <>
                                            <div className="Header-nav">
                                                <Nav
                                                    showLanguage={showLanguage}
                                                    referenceType={referenceType}
                                                />
                                            </div>
                                            <NavOpener variant="static" />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </Container>
        </header>
    )
}
