import './index.styl'
import { Fragment, ReactElement } from 'react'
import { Table } from '../../../../components/Table'
import { DetailPayedProps } from '../../../../models/DetailPayedResults/detailPayedSectionDefinitionModel'
import { Translate, _ } from '../../../../translations/CebiaTranslator'
import { ToggleOpenerGeneralSimple } from '../../../../components/ToggleOpenerGeneralSimple'
import { CountryCode } from '../../../../components/AtrMileageTable/countryCodes'
import { ToggleItemGeneral } from '../../../../components/ToggleItemGeneral'
import { LoadArea } from '../../../../components/LoadArea'
import { i18n } from '../../../../translations/i18n'
import { getCountries } from '../../../../busLog/enums_gen'

export const SectionTaxi = ({ sourceData, isPrint }: DetailPayedProps): ReactElement => {
    if (!sourceData?.taxiControlResult) return <></>
    const tran = new Translate()
    const countries = getCountries(tran)
    return (
        <>
            <div className="sectionTaxi">
                <div className="pb-25 text-center">
                    {!isPrint && <span>{tran.GetText('Výsledek kontroly')}</span>}
                    {sourceData?.taxiControlResult?.isTaxi ?? false ? (
                        <>
                            <h2 className="warning font-bold">
                                {tran.GetText('Provozováno jako taxi')}
                            </h2>
                            <p className="text-justify">
                                {tran.GetHtml(
                                    'V dostupných databázích bylo zjištěno, že vozidlo bylo nebo stále je <span class="warning">provozováno jako taxi</span>. Důkladně zkontrolujte technický stav vozu.',
                                )}
                            </p>
                        </>
                    ) : (
                        <>
                            <h2 className="noTaxiText font-bold">
                                {tran.GetText('Nebylo provozováno jako taxi v ČR')}
                            </h2>
                            <p className="text-justify">
                                {tran.GetText(
                                    'V dostupných databázích bylo zjištěno, že vozidlo nebylo a v současnosti není provozováno jako vůz taxislužby.',
                                )}
                            </p>
                        </>
                    )}
                </div>

                <div className="mb-30 Grid gmb-5">
                    {!isPrint &&
                        (sourceData.taxiControlResult.taxiItems?.length ?? 0) > 0 &&
                        sourceData.taxiControlResult.taxiItems?.map((item, index) => (
                            <div key={index}>
                                <ToggleOpenerGeneralSimple
                                    href={`#togleTaxi${item.countryFlagName}`}
                                    countryCode={item.countryFlagName as CountryCode}
                                    titleLeft={item.countryName}
                                    titleRight={
                                        item.hasTaxiDefinition
                                            ? tran.GetText('Záznam nalezen')
                                            : tran.GetText('Nebylo provozováno jako taxi v ČR')
                                    }
                                    isOpened={false}
                                    button={
                                        item.hasTaxiDefinition ? tran.GetText('Detail') : undefined
                                    }
                                    showRightChecked={!item.hasTaxiDefinition}
                                    showAlertTitleRight={item.hasTaxiDefinition}
                                />
                                {item.hasTaxiDefinition && (
                                    <ToggleItemGeneral id={`togleTaxi${item.countryFlagName}`}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="text-left" colSpan={2}>
                                                        {tran.GetText(
                                                            'Záznamy o provozu vozidla jako taxi',
                                                        )}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.taxiDefinition?.map((taxiDefItem, index) => (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            <td className="text-left">
                                                                {tran.GetText(
                                                                    'Provozováno jako taxi v roce',
                                                                )}
                                                            </td>
                                                            <td className="text-left">
                                                                {taxiDefItem.year}
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div className="mt-40 text-left">
                                            <p>
                                                {!isPrint &&
                                                    tran.GetText(
                                                        'Pozn.: Vozidlo mohlo být provozováno jako taxi pouze po určitou dobu v uvedeném období.',
                                                    )}
                                            </p>
                                        </div>
                                    </ToggleItemGeneral>
                                )}
                            </div>
                        ))}
                    {(i18n.language === 'cz' || i18n.language === 'sk') && !isPrint && (
                        <div key="sk1">
                            <ToggleOpenerGeneralSimple
                                href={`https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/`}
                                clickAction={'open'}
                                countryCode="sk"
                                titleLeft={tran.GetText('Slovensko')}
                                titleRight={tran.GetText('Dostupné po zadání SPZ vozidla')}
                                isOpened={false}
                                button={tran.GetText('Prověřit')}
                                showRightChecked={false}
                                showAlertTitleRight={false}
                                showArrowDown={false}
                                hrefBlank={true}
                                showArrowRight={true}
                            />
                        </div>
                    )}
                    {isPrint &&
                        (sourceData.taxiControlResult.taxiItems?.length ?? 0) > 0 &&
                        sourceData.taxiControlResult.taxiItems?.map((item, index) => (
                            <div key={index}>
                                {item.hasTaxiDefinition && (
                                    <>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="text-left" colSpan={2}>
                                                        {tran.GetText(
                                                            'Záznamy o provozu vozidla jako taxi',
                                                        )}
                                                        {' - '}
                                                        {
                                                            countries.find(
                                                                x =>
                                                                    x.code2 == item.countryFlagName,
                                                            )?.name
                                                        }
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.taxiDefinition?.map((taxiDefItem, index) => (
                                                    <Fragment key={index}>
                                                        <tr>
                                                            <td>
                                                                {tran.GetText(
                                                                    'Provozováno jako taxi v roce',
                                                                )}
                                                            </td>
                                                            <td>{taxiDefItem.year}</td>
                                                        </tr>
                                                    </Fragment>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div className="text-left pb-10">
                                            <p>
                                                {tran.GetText(
                                                    'Pozn.: Vozidlo mohlo být provozováno jako taxi pouze po určitou dobu v uvedeném období.',
                                                )}
                                            </p>
                                        </div>
                                        <div className="text-justify pb-25">
                                            <p>
                                                {tran.GetHtml(
                                                    'Kontrolu taxi na Slovensku lze provést zadáním SPZ vozidla na <a href="https://www.jiscd.sk/registre/registre-taxi/overenie-vozidla-taxisluzby/">www.jiscd.sk</a>',
                                                )}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </div>
            <LoadArea
                hideBackGround={true}
                position="inherit"
                loader={{ variant: 'bars', size: 'big' }}
                align="center"
                isLoading={!sourceData?.taxiControlResult}
            />
        </>
    )
}
