import './index.styl'
import { ReactElement, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { isPayed } from '../../busLog/payedBL'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { useAppContext } from '../../contexts/AppContext'
import { useSignalRConnection } from '../../contexts/SignalR/SignalRHook'
import { SignalRState } from '../../contexts/SignalR/SignalRSetting'
import { ReferenceType } from '../../enums/ReferenceType'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'
import { PayedJsonResult } from '../../models/DetailPayedResults/payedJsonResult'
import { Translate } from '../../translations/CebiaTranslator'
import { redirectDotazLang, scrollToTop } from '../../utils/redirect'
import { SectionMain } from '../detailPaid/sections/SectionMain'
import { SectionOverview } from '../detailPaid/sections/SectionOverview'
import { PageHeader } from '../../components/PageHeader'
import { StickyTypeMessage } from '../../components/AtrOverviewCar'
import { useSearchParams } from 'react-router-dom'
import { BubbleOpener } from '../../components/BubbleOpener'
import { Container } from '../../components/Container'
import { Modal, useModal } from '../../components/Modal'
import { SmartCodeModal } from '../../components/Modal/SmartCodeModal'
import moment from 'moment'
import { Poster } from '../../components/Poster'
import { Section } from '../../components/Section'
import { StickySwapper } from '../../components/StickySwapper'
import { Button } from '../../components/Button'
import { SectionHighlights } from '../detailDashboard/sections/SectionHighlights'
import { GoogleWidgetDashboard } from '../../components/GoogleWidgetDashboard'
import { SectionMap } from '../detailDashboard/sections/SectionMap'

export const PageDetailSmartCode = (): ReactElement => {
    const tran = new Translate()
    const navigate = useNavigate()
    const appContext = useAppContext()
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const q = searchParams.get('q')
    const utm_campaign = searchParams.get('utm_campaign') ?? ''
    const utm_content = searchParams.get('utm_content') ?? ''
    const utm_source = searchParams.get('utm_source') ?? ''
    const utm_medium = searchParams.get('utm_medium') ?? ''

    const [smartCodeBaseModel, setSmartCodeBaseModel] = useState<PayedJsonResult[]>([])
    const {
        openConnection,
        bufferedData,
        canCloseConnection,
        closeConnection,
        connectionId,
        hubState,
    } = useSignalRConnection({ url: SignalRUrlType.Payed })
    const { retVal, isError } = isPayed(
        id!,
        connectionId,
        ReferenceType.SmartCode,
        utm_content,
        utm_campaign,
    )
    const [dowloadedSections, setDownloadedSections] = useState(0)
    const { open: openC, close: close } = useModal('smartCodeModal')

    const [stickyMessages, setStickyMessages] = useState<StickyTypeMessage[]>([])

    useEffect(() => {
        if (hubState === SignalRState.HubCreated) openConnection()
    }, [hubState])

    useEffect(() => {
        if ((bufferedData?.length ?? 0) > 0) {
            setDownloadedSections(prevState => bufferedData.length)

            bufferedData.forEach(element => {
                const item = element as PayedJsonResult

                if (item != null) {
                    if (item.sectionId == 128) appContext.setLoading(false)
                    setSmartCodeBaseModel(y => {
                        const newArr = y.filter(z => z.sectionId !== item.sectionId)
                        return [...newArr, ...[item]]
                    })
                }
            })
        }

        // refetch()
        if (canCloseConnection(bufferedData)) {
            appContext.setLoading(false)
            closeConnection()
        } else {
            appContext.setLoading(true)
        }
    }, [bufferedData])

    useEffect(() => {
        if (retVal) {
            retVal.couponCode = ''
            if (retVal.isValid !== 1) {
                if (retVal.isValid === 2) {
                    window.location.assign(`/carinfo/payment/${id}`)
                } else {
                    window.location.assign('/')
                }
            }
            appContext.storePayedStateModel(retVal)
            console.log('retVal', retVal)
            ///Takto ukoncim spojeni, uz nepotrebuji nic dotahovat.
            appContext.storeClientConnectionId('')
            redirectDotazLang(retVal.countryId)

            if (retVal.isSample) {
                setStickyMessages(y => {
                    return [
                        {
                            text: tran.GetText('UKÁZKA výsledku prověření náhodného vozidla.'),
                            sectionId: 0,
                        },
                    ]
                })
            }
        }
    }, [retVal])

    useEffect(() => {
        if (isError) window.location.assign(`/payed/detail/${id}`)
    }, [isError])
    return (
        <div className="PageDetailSmartCode">
            <Header showLanguage={false} referenceType={ReferenceType.SmartCode} />
            <PageHeader>
                <div className="SectionMain-categoryHeaderSmart color-darkBlue ">
                    <h2 className="Beta text-center">
                        {retVal && retVal?.isSample
                            ? tran.GetText('Ukázka základního prověření vozidla')
                            : tran.GetText('Základní prověření vozidla')}

                        <BubbleOpener className="ml-5 Gray" onClickHandler={openC} />
                    </h2>
                    <span className="mb-10 md:mb-20 text-center basicListing">
                        {retVal?.couponActivation
                            ? `${tran.GetText('Ke dni')}${' '}${moment(
                                  retVal?.couponActivation,
                              ).format('D.M.YYYY')}`
                            : ''}
                    </span>

                    <Modal id="smartCodeModal">
                        <Container>
                            <SmartCodeModal model={retVal} closeModal={close} />
                        </Container>
                    </Modal>
                </div>
            </PageHeader>
            <SectionOverview
                variant="full"
                model={retVal}
                showActionButtons={false}
                stickyMessages={stickyMessages}
            />
            <SectionMain
                referenceType={ReferenceType.SmartCode}
                sourceData={smartCodeBaseModel}
                model={retVal}
                showLoader={false}
            />
            {retVal && !retVal?.isSample && (
                <Section className="pb-0 pt-80">
                    <Container variant="medium">
                        <StickySwapper stickyRange="both" offset={0}>
                            {({ intersectionRef, staticVisible, stickyActive }) => [
                                <Poster
                                    key="primary"
                                    variant="basicList"
                                    variantDisplay="static"
                                    customRef={intersectionRef}
                                    active={staticVisible}
                                >
                                    <div className="Grid justify-center align-center mt-30 mb-30">
                                        <div className="Grid-cell w-full mb-20 lg:mb-10 smw:w-12/12 md:w-12/12 lg:w-5/12 text-center lg:text-left mt-10 borderRight">
                                            <span className="font-lg pr-10">
                                                {tran.GetText(
                                                    'Získejte kompletní prověření se slevou',
                                                )}{' '}
                                                {retVal?.discount}
                                            </span>
                                        </div>
                                        <div className="Grid-cell w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 lg:w-5/12 mb-5 lg:mt-5 smw:mb-5 sm:mb-5 text-center md:text-center lg:text-right sm:text-center smw:text-center">
                                            <span className="orange font-lg cross pl-20">
                                                {retVal?.standardPrice.toFixed(
                                                    retVal.decimalPlaces ?? 0,
                                                )}{' '}
                                                {retVal?.currency}
                                            </span>
                                            <span className="color-green font-xxxl pl-20">
                                                {retVal?.price} {retVal?.currency}
                                            </span>
                                        </div>
                                        <div className="Grid-cell w-12/12 sm:w-6/12 md:w-2/12 smw:w-6/12 md:w-6/12 text-center lg:w-2/12 md:text-center lg:text-right sm:text-center smw:text-center">
                                            <Button
                                                as="button"
                                                variantColor="green"
                                                variantSize="small"
                                                className="w-10/12 lg:w-8/12 smw:w-6/12"
                                                onClick={() => {
                                                    scrollToTop()
                                                    navigate(
                                                        `/?vin=${retVal?.carVin}&utm_source=${
                                                            utm_source.length > 0
                                                                ? utm_source
                                                                : 'Smart_kod'
                                                        }&utm_medium=${
                                                            utm_medium.length > 0
                                                                ? utm_medium
                                                                : 'tlacitko'
                                                        }&utm_campaign=${
                                                            utm_campaign.length > 0
                                                                ? utm_campaign
                                                                : q === null
                                                                ? 'Smart_kod'
                                                                : 'Smart_kodQR'
                                                        }&requestId=${id}&pay=1`,
                                                    )
                                                }}
                                            >
                                                {tran.GetText('Koupit')}
                                            </Button>
                                        </div>
                                    </div>
                                </Poster>,

                                <Poster
                                    key="secondary"
                                    variant="basicList"
                                    variantDisplay="fixed"
                                    active={!staticVisible && stickyActive}
                                >
                                    <div className="Grid gmb-10">
                                        <div className="Grid-cell w-full mb-0 mt-0 lg:mt-10 lg:mb-10 md:mb-10 smw:w-12/12 md:w-12/12 lg:w-4/12 text-center lg:text-left borderRight">
                                            <span className="font-lg pr-10">
                                                {tran.GetText(
                                                    'Získejte kompletní prověření se slevou',
                                                )}{' '}
                                                {retVal?.discount}
                                            </span>
                                        </div>
                                        <div className="Grid-cell w-12/12 sm:w-6/12 smw:w-6/12 md:w-6/12 lg:w-6/12 mt-5 text-center md:text-center lg:text-right sm:text-center smw:text-center">
                                            <span className="orange font-lg cross pl-20">
                                                {retVal?.standardPrice.toFixed(
                                                    retVal.decimalPlaces ?? 0,
                                                )}{' '}
                                                {retVal?.currency}
                                            </span>
                                            <span className="color-green font-xxxl pl-20">
                                                {retVal?.price} {retVal?.currency}
                                            </span>
                                        </div>
                                        <div className="Grid-cell w-12/12 sm:w-6/12 md:w-2/12 smw:w-6/12 md:w-6/12 text-center lg:w-2/12 md:text-center lg:text-right sm:text-center smw:text-center">
                                            <Button
                                                as="button"
                                                variantColor="green"
                                                variantSize="small"
                                                className="w-12/12 lg:w-8/12 smw:w-6/12"
                                                onClick={() => {
                                                    scrollToTop()
                                                    navigate(
                                                        `/?vin=${retVal?.carVin}&utm_source=${
                                                            utm_source.length > 0
                                                                ? utm_source
                                                                : 'Smart_kod'
                                                        }&utm_medium=${
                                                            utm_medium.length > 0
                                                                ? utm_medium
                                                                : 'tlacitko'
                                                        }tlacitko&utm_campaign=${
                                                            utm_campaign.length > 0
                                                                ? utm_campaign
                                                                : q === null
                                                                ? 'Smart_kod'
                                                                : 'Smart_kodQR'
                                                        }&requestId=${id}&pay=1`,
                                                    )
                                                }}
                                            >
                                                {tran.GetText('Koupit')}
                                            </Button>
                                        </div>
                                    </div>
                                </Poster>,
                            ]}
                        </StickySwapper>
                    </Container>
                </Section>
            )}
            <SectionHighlights />
            <GoogleWidgetDashboard showTitle={false} />
            <SectionMap />
            <Footer />
        </div>
    )
}
