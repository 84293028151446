import './index.styl'
import { ReactElement } from 'react'
import { Translate } from '../../../../translations/CebiaTranslator'
import { Section } from '../../../../components/Section'

export const SectionLeasingZV = (): ReactElement => {
    const tran = new Translate()

    return (
        <Section className="LockedSection">
            <div className="text-center pb-25">
                <h2 className="orange font-bold pt-10">
                    {tran.GetText('Kategorie je dostupná pouze v kompletním prověření')}
                </h2>
            </div>
            <p className="text-justify pb-25">
                {tran.GetHtml(
                    'Lze zkontrolovat, zda na vozidlo není vedena zástava, leasing nebo jiné finanční omezení, které by mohlo komplikovat jeho prodej či další užívání. Tato data jsou dostupná pouze pro vozidla zaregistrovaná v České republice a na Slovensku.',
                )}
            </p>
        </Section>
    )
}
