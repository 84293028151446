import classNames from 'classnames'
import { Formik } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import validate from '../../busLog/validationBL'
import { useAppContext } from '../../contexts/AppContext'
import { useSignalRConnection } from '../../contexts/SignalR/SignalRHook'
import { SignalRUrlType } from '../../enums/SignalRUrlEnum'
import { UTMModel } from '../../models/utmModel'
import { ValidationResponseModel } from '../../models/validationResponseModel'
import { VinModel } from '../../models/vinModel'
import { Translate } from '../../translations/CebiaTranslator'
import { scrollToTop } from '../../utils/redirect'
import { Button, ButtonProps } from '../Button'
import { Container } from '../Container'
import { InputWithCounter, InputWithCounterProps } from '../InputWithCounter'
import { Modal, useModal } from '../Modal'
import { VinModalBlackList } from '../Modal/VinModalBlackList'
import './index.styl'
import { i18n } from '../../translations/i18n'

type Variant = 'default' | 'static' | 'fixed'

type Props = {
    variant: Variant
    id: string
}

type RefProp = {
    inputWithCounterProps: Partial<InputWithCounterProps>
    buttonProps: Partial<Omit<ButtonProps<typeof Button>, 'as'>>
}

type RefProps = Record<Variant, RefProp>

const refProps: RefProps = {
    default: {
        inputWithCounterProps: {
            variantSize: 'desktopLarge',
            nogap: true,
        },
        buttonProps: {
            variantSize: 'desktopLarge',
            className: 'w-full',
        },
    },
    static: {
        inputWithCounterProps: {
            variantSize: 'desktopLarge',
            nogap: true,
        },
        buttonProps: {
            variantSize: 'desktopLarge',
            className: 'w-full',
        },
    },
    fixed: {
        inputWithCounterProps: { nogap: true },
        buttonProps: {
            variant: 'mobileIcon',
        },
    },
}

const AutoSubmitComponent = ({ variant }: Props) => {
    const appContext = useAppContext()
    const [searchParams] = useSearchParams()
    const urlVin = searchParams.get('vin') ?? searchParams.get('s_vin')
    useEffect(() => {
        if (urlVin) {
            const { appModel } = appContext
            appModel.vin = urlVin
            appContext.storeAppModel(appModel, false)
        }
        // use your own equality test or react-fast-compare because they are probably different objects
        if (urlVin && appContext.appModel.vin == urlVin && variant === 'default') {
            // Nastavíme interval, který bude každých 100 milisekund kontrolovat, zda je tlačítko dostupné
            const intervalId = setInterval(() => {
                const button = document.getElementById(
                    'submitVinButtondefault',
                ) as HTMLButtonElement

                if (button) {
                    // Klikneme na tlačítko, pokud je k dispozici
                    button.click()
                    clearInterval(intervalId) // Zastavíme interval, protože tlačítko bylo nalezeno
                }
            }, 100)
        }
    }, [])
    // not listening for initialValues, because even if they are updated you probably don't want to autosave.
    return null
}

export const FormVin = ({ variant, id }: Props): ReactElement => {
    const appContext = useAppContext()
    const [searchParams] = useSearchParams()
    const urlVin = searchParams.get('vin') ?? searchParams.get('s_vin')
    const [vin, setVin] = useState(appContext.appModel.vin ?? '')
    const { executeRecaptcha } = useGoogleReCaptcha()
    const { open, close } = useModal('blackList')
    const navigate = useNavigate()
    let utm_campaign = searchParams.get('utm_campaign') ?? ''
    if (i18n.language == 'ro' && utm_campaign.length == 0) {
        utm_campaign = 'reducerero202024'
    }
    if (i18n.language == 'it' && utm_campaign.length == 0) {
        utm_campaign = 'sleva312024'
    }
    if (i18n.language == 'fr' && utm_campaign.length == 0) {
        utm_campaign = 'sleva312024'
    }
    const utm_content = searchParams.get('utm_content') ?? ''
    const utm_source = searchParams.get('utm_source') ?? ''
    const utm_medium = searchParams.get('utm_medium') ?? ''
    const requestId = searchParams.get('requestId') ?? ''
    const pay = searchParams.get('pay') ?? ''

    const { openConnection, closeConnection, connectionId } = useSignalRConnection({
        url: SignalRUrlType.DashBoard,
    })
    const tran = new Translate()
    const validationSchema = () => {
        return Yup.object({
            userVin: Yup.string()
                .max(17, tran.GetText('Délka VIN musí být 17 znaků'))
                .min(17, tran.GetText('Délka VIN musí být 17 znaků'))
                .matches(/^[A-Z0-9]+$/, tran.GetText('VIN obsahuje nepovolené znaky')),
        })
    }

    useEffect(() => {
        const { appModel } = appContext
        appContext.appModel.utmModel = new UTMModel(
            utm_campaign.length > 0
                ? utm_campaign
                : appModel.utmModel.utm_campaign.length > 0
                ? appModel.utmModel.utm_campaign
                : '',
            utm_content,
            utm_source,
            utm_medium,
        )
        appContext.storeAppModel(appContext.appModel, false)
    }, [])

    useEffect(() => {
        if (variant == 'default' || !urlVin) {
            onOpenedConnection(variant)
        }
    }, [connectionId])

    const inputWithCounterProps: InputWithCounterProps = {
        ...refProps[variant].inputWithCounterProps,
        label: tran.GetText('Zadejte VIN'),
        maxLength: 17,
        id: id,
        value: appContext.appModel.vin,
        name: 'userVin',
        length: appContext?.appModel?.vin?.length ?? 0,
        initialerror: '',
        onChange: value => {
            const { appModel } = appContext
            appModel.vin = value as string
            appModel.vinError = ''
            setVin(value as string)
            appContext.storeAppModel(appModel, false)
        },
    }
    const validateAndSubmit = async (e: any) => {
        const vinRegex = /^[A-Z0-9]{17}$/
        const variant = e.target.id.replace('userVinForm', '')

        e.preventDefault()
        if (appContext.appModel.vinError !== '' || appContext.appModel.vin.length !== 17) {
            return
        }

        if (!vinRegex.test(appContext.appModel.vin)) {
            return
        }

        appContext.setLoading(true)
        setVin(
            e.target.elements.userVin.value.length > 0
                ? e.target.elements.userVin.value
                : appContext.appModel.vin,
        )
        const { appModel } = appContext
        appModel.vin = vin
        appModel.variant = variant
        appContext.storeAppModel(appModel, false)
        openConnection()
    }

    async function onOpenedConnection(variant: Variant) {
        if (
            (connectionId?.length ?? 0) <= 0 ||
            (vin?.length ?? 0) <= 0 ||
            variant != appContext.appModel.variant
        )
            return
        const { appModel } = appContext
        const token = await generateToken()
        appModel.validateResponseModel = new ValidationResponseModel(null)
        appContext.storeAppModel(appModel, false)

        const model = new VinModel(
            appContext.appModel.vin.length > 0 ? appContext.appModel.vin : vin,
            token ?? '',
            connectionId!,
        )
        ;(model.utm_campaign =
            utm_campaign.length > 0
                ? utm_campaign
                : appModel.utmModel.utm_campaign.length > 0
                ? appModel.utmModel.utm_campaign
                : ''),
            (model.utm_content = utm_content ?? '')
        model.utm_medium = utm_medium ?? ''
        model.utm_source = utm_source ?? ''
        model.requestId = requestId ?? ''
        const result = await validate.validateVin(model)
        if (result.isValid === 0) {
            appContext.setVinError(result.message)
            if (result.isOnBlackList) {
                open()
            }
            appContext.setLoading(false)
        }
        appModel.validateResponseModel = result
        appModel.vin = result.carVin ?? model.Vin
        appModel.viewId = result.viewId
        appContext.storeAppModel(appModel, false)
        if (!appModel.vinError && !result.isOnBlackList) {
            scrollToTop()
            navigate(
                '/carInfo/' +
                    result.viewId +
                    (utm_campaign != null
                        ? '?utm_campaign=' + appModel.utmModel.utm_campaign
                        : '') +
                    (pay != null && pay === '1' ? '&pay=1' : ''),
            )
        } else {
            closeConnection()
        }
    }

    const generateToken = async () => {
        if (!executeRecaptcha) {
            return
        }
        const token = await executeRecaptcha('contact')
        return token
    }

    const buttonProps = refProps[variant].buttonProps
    const className = classNames('FormVin', {
        [`FormVin--${variant}`]: variant,
    })
    useEffect(() => {
        const interval = setInterval(() => {
            if (appContext.appModel.vin && vin != appContext.appModel.vin)
                setVin(appContext.appModel.vin)
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setVin(urlVin ?? appContext.appModel.vin)
    }, [urlVin])
    return (
        <>
            <Modal id="blackList">
                <Container>
                    <VinModalBlackList></VinModalBlackList>
                </Container>
            </Modal>
            <Formik
                initialValues={{ userVin: appContext.appModel.vin }}
                validate={async () => {}}
                onSubmit={() => {}}
                validationSchema={validationSchema}
            >
                {formik => {
                    return (
                        <form
                            id={`userVinForm${variant}`}
                            className={className}
                            onSubmitCapture={async values => {
                                validateAndSubmit(values)
                            }}
                        >
                            <div className="FormVin-input">
                                <InputWithCounter
                                    {...inputWithCounterProps}
                                    showErrorMessage={false}
                                />
                                {appContext.appModel.vinError && (
                                    <div className="InputError">{appContext.appModel.vinError}</div>
                                )}
                                {formik.touched.userVin && formik.errors.userVin && (
                                    <div className="InputError">{formik.errors.userVin}</div>
                                )}
                            </div>
                            <div className="FormVin-button">
                                <Button
                                    id={`submitVinButton${variant}`}
                                    as="button"
                                    {...buttonProps}
                                    className="w-full"
                                    icon="arrowRight"
                                >
                                    {tran.GetText('Prověřit')}
                                </Button>
                            </div>
                            {variant == 'default' && executeRecaptcha && (
                                <AutoSubmitComponent id="" variant={variant}></AutoSubmitComponent>
                            )}
                        </form>
                    )
                }}
            </Formik>
        </>
    )
}
