import { CouponType } from '../enums/CouponType'
import { ShowDateEnum } from '../enums/ShowDateEnum'
import { SmartCodeType } from '../enums/smartCodeType'
import { AtrCebiaReport } from './atrCebiaReportModel'
import { CebiaReportEsa } from './payedRegResponseModel'

export interface EcommerceData {
    firstUse: boolean
    cuponNumber: string
    productName: string
    discountCode: string
    price: number
    currencyCode: string
    email: string
    cuponSale: string
}

export class PayedStateModel {
    isValid = 0
    viewId = ''
    imageData = ''
    carMake = ''
    carModel = ''
    carVin = ''
    typ = ''
    druh = ''
    fuel = ''
    kw = ''
    ccm = ''
    gearBox = ''
    productYear: Date | undefined
    showProductDate: ShowDateEnum = 1
    firstRegistration: Date | undefined
    controlDigits = ''
    isCoupon = 0
    atrStatus = 0
    crStatus = 0
    crLimitOk = 0
    couponCode = ''
    couponValidity: Date | undefined
    couponActivation: Date | undefined
    smartCodeActivation: Date | undefined
    couponType: CouponType = CouponType.WebCustomer
    smartCodeType: SmartCodeType = SmartCodeType.None
    isSample = false
    kmAge = 0
    cebiaReportEsa: CebiaReportEsa | undefined
    atrCebiaReport: AtrCebiaReport | undefined
    apc = ''
    inApcRefNumber = ''
    ecommerceData: EcommerceData | undefined
    printDoc = ''
    smartCodeDoc = ''
    countryId = 0
    apcMessage = ''
    accessMessage = ''
    errorMessage = ''
    buyedByCompany = 0
    historieProverovaniAPC: [string] | undefined
    dotazTypId = 0
    price = 0
    isFull?: boolean
    dotazProductId = 0
    currency = ''
    standardPrice = 0
    discount = ''
    firstUse?: boolean
    decimalPlaces = 0
}
